import { createRouter, createWebHistory } from 'vue-router'
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: () => import('../components/Login')
        },
        {
            path: '/qr/:eventId',
            name: 'QR',
            component: () => import('../components/InitApp')
        },
        {
            path: '/event',
            name: 'Event',
            component: () => import('../components/Events')
        },
        {
            path: '/persons/:eventId',
            name: 'Participantes',
            component: () => import('../components/Persons')
        },
        {
            path : '/:catchAll(.*)',
            name:"Redirect",
            component: () => import('../components/Login')

        }
    ]
})
export default router;