import axios from 'axios'
import indexBD from '../serviceBDLocal/indexBD'

const login = (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post('https://registroapi.aplicacionesincontacto.com/user/login',
                {
                    username: data.username,
                    password: data.password,
                    browser: getBrowser(),
                    os: getSystemOperativo(),
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))

    })
}

const getEvents = () => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        let token = users[0].token;
        axios
            .get('https://registroapi.aplicacionesincontacto.com/exhibitor/events/users/' + diff_to_GMT(),
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))

    })
}

const syncPerson = (eventId, dataSync) => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        let token = users[0].token;
        axios
            .post('https://registroapi.aplicacionesincontacto.com/exhibitor/user/event/' + eventId + '/asyn/leads',
                dataSync,
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))

    });
}



const reportError = (eventId, dataSync, message) => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        let token = users[0].token;
        axios
            .post('https://registroapi.aplicacionesincontacto.com/exhibitor/user/event/' + eventId + '/reporterror/leads',
                {
                    dataSync,
                    message
                },
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))

    });
}

const getDataBanners = (eventId) => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        let token = users[0].token;
        axios
            .get('https://registroapi.aplicacionesincontacto.com/event/' + eventId + '/bannerleads',
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))

    });
}

const saveHistoryLeads = (eventId, data) => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        let token = users[0].token;
        axios
            .post('https://registroapi.aplicacionesincontacto.com/event/' + eventId + '/history/leads/save',
                data,
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))

    });
}


const diff_to_GMT = () => {
    let now = new Date();
    return -(now.getTimezoneOffset()) / 60;
}

const getBrowser = () => {
    var ua = navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
}
const getSystemOperativo = () => {
    var OSName = "Desconocido";
    if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
    if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
    if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
    if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";
    if (navigator.appVersion.indexOf("Android") != -1) OSName = "Android";

    return OSName;
}


export default {
    login,
    getEvents,
    syncPerson,
    reportError,
    getSystemOperativo,
    getBrowser,
    getDataBanners,
    saveHistoryLeads
}