
const setLocalstorage = (status) => {
    localStorage.setItem("cnapp", status);
}
const getLocalstorage = () => {
    let conection = true;
    let valueStorage = localStorage.getItem("cnapp");
    if (valueStorage) {
        conection = JSON.parse(valueStorage);
    }
    return conection;
}

const getUsers = async (db) => {
    return new Promise((resolve, reject) => {
        try {

            let trans = db.transaction(['users'], 'readonly');
            trans.oncomplete = e => {
                console.log(e ? '' : '');
                resolve(users);
            };
            let store = trans.objectStore('users');
            let users = [];

            store.openCursor().onsuccess = e => {
                let cursor = e.target.result;
                if (cursor) {
                    let data = cursor.value;
                    data.indexedDBId = cursor.primaryKey;
                    users.push(data)
                    cursor.continue();
                }
            };
        } catch (error) {
            reject(error)
        }


    });
}
const closeSession = (db) => {
    return new Promise(async (resolve, reject) => {
        try {

            let event = await getEvents(db);
            if (event && event.length > 0) {
                let trans = db.transaction(['events'], 'readwrite');
                for(let e of event){
                    let store = trans.objectStore('events').delete(e.indexedDBId)
                }
                trans.oncomplete = () => {
                }
            }

            let users = await getUsers(db);
            if (users && users.length > 0) {
                let trans = db.transaction(['users'], 'readwrite');
                let store = trans.objectStore('users').delete(users[0].indexedDBId);
                trans.oncomplete = () => {
                    resolve({});
                }
            } else {
                resolve({});
            }

        } catch (error) {
            reject(error)
        }
    });
}

const getEvents =  async (db) => {
    return new Promise((resolve, reject) => {
        try {
            let trans = db.transaction(['events'], 'readonly');
            trans.oncomplete = e => {
                console.log(e ? '' : '');
                resolve(events);
            };
            let store = trans.objectStore('events');
            let events = [];

            store.openCursor().onsuccess = e => {
                let cursor = e.target.result;
                if (cursor) {
                    let data = cursor.value;
                    data.indexedDBId = cursor.primaryKey;
                    events.push(data)
                    cursor.continue();
                }
            };
        } catch (error) {
            reject(error)
        }
    });
}

export default {
    getDb: () => {
        return new Promise((resolve, reject) => {
            let request = window.indexedDB.open("registronuevo", 2);
            request.onerror = e => {
                console.log('Error opening db', e);
                reject('Error');
            };
            request.onsuccess = e => {
                resolve(e.target.result);
            };
            request.onupgradeneeded = e => {
                console.log('onupgradeneeded');
                let db = e.target.result;
                let objectStore = db.createObjectStore("assistantsQr", { autoIncrement: true, keyPath: 'id' });
                let objectStore2 = db.createObjectStore("users", { autoIncrement: true, keyPath: 'id' });
                let objectStore3 = db.createObjectStore("events", { autoIncrement: true, keyPath: 'id' });
                console.log(objectStore, objectStore2, objectStore3);
            };
        });
    },
    addAssistant: async (assistant, db) => {
        let trans = db.transaction(['assistantsQr'], 'readwrite');
        trans.oncomplete = e => {
            console.log(e)
        };
        let store = trans.objectStore('assistantsQr');
        store.add(assistant);
    },
    addUser: async (user, db) => {
        console.log('about to add ' + JSON.stringify(user));
        let trans = db.transaction(['users'], 'readwrite');
        trans.oncomplete = e => {
            console.log(e)
        };
        let store = trans.objectStore('users');
        store.add(user);
    },

    getPerson: async (db, eventId) => {
        return new Promise((resolve, reject) => {
            try {
                let trans = db.transaction(['assistantsQr'], 'readonly');
                trans.oncomplete = e => {
                    persons = persons.filter(v => v.eventId == eventId);
                    resolve(persons);
                };
                let store = trans.objectStore('assistantsQr');
                let persons = [];

                store.openCursor().onsuccess = e => {
                    let cursor = e.target.result;
                    if (cursor) {
                        let data = cursor.value;
                        data.indexedDBId = cursor.primaryKey;
                        persons.push(data)
                        cursor.continue();
                    }
                };
            } catch (error) {
                reject(error)
            }

        });
    },
    addEvents: async (events, db) => {
        for (let event of events) {
            let trans = db.transaction(['events'], 'readwrite');
            trans.oncomplete = e => {
                console.log(e)
            };
            let store = trans.objectStore('events');
            store.add(event);
        }

    },
    updatePerson: async (db, indexedDBId, data) => {
        return new Promise((resolve, reject) => {
            try {
                let trans = db.transaction(['assistantsQr'], 'readwrite');
                let store = trans.objectStore('assistantsQr');

                store.openCursor().onsuccess = e => {
                    let cursor = e.target.result;
                    if (cursor) {
                        if (cursor.key == indexedDBId) {
                            const invoice = cursor.value;
                            for (let prop in data) {
                                invoice[prop] = data[prop]
                            }
                            const updateRequest = cursor.update(invoice);
                        }
                        cursor.continue();
                    }
                };

                resolve({})
            } catch (error) {
                reject(error)
            }

        });
    },
    updateEvent: async (db, indexedDBId, data) => {
        return new Promise((resolve, reject) => {
            try {
                let trans = db.transaction(['events'], 'readwrite');
                let store = trans.objectStore('events');
                store.openCursor().onsuccess = e => {
                    let cursor = e.target.result;
                    if (cursor) {
                        if (cursor.key == indexedDBId) {
                            const invoice = cursor.value;
                            for (let prop in data) {
                                invoice[prop] = data[prop]
                            }
                            const updateRequest = cursor.update(invoice);
                        }
                        cursor.continue();
                    }
                };
                resolve({})
            } catch (error) {
                reject(error)
            }

        });
    },
    setLocalstorage,
    getLocalstorage,
    closeSession,
    getUsers,
    getEvents
}