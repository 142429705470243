<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <v-offline @detected-condition="setConnected">
  </v-offline>
  <router-view></router-view>

  <div class="messageInstallApp" v-if="installApp">
    <div class="card-view-APP">
      <div class="ios">
        <div>
          <h5>
            <b>
              Instalar Incontacto Leads
            </b>
            <svg-icon type="mdi" @click="closeMeesage" color="#0094d2" :path="mdiClose" :size="28"> </svg-icon>
          </h5>
        </div>
        <div style="text-align: left;">
          Puedes agregar esta aplicación a la página de inicio de tu dispositivo para que accedas a ella fácil y rápido.
        </div>
        <div style="text-align: left;">
          <b>1.</b> Toca el ícono <svg-icon type="mdi" color="#0094d2" :path="mdiExportVariant" :size="18"> </svg-icon>
          en la parte inferior o seperior de la pantalla
        </div>
        <div style="text-align: left;">
          <b>2.</b> Selecciona la opción "Agregar a inicio"
        </div>
      </div>
      <div class="android">

      </div>

    </div>
  </div>
</template>

<script setup>
import service from './serviceBDLocal/indexBD';
import _apis from './serviceBDLocal/apis';
import $router from './router';
import { mdiExportVariant, mdiClose } from '@mdi/js';
import { onMounted, inject, ref } from 'vue';
const installApp = ref(false);
const swal = inject('$swal');
const setConnected = (e) => {
  service.setLocalstorage(e);
};

const closeMeesage = () => {
  installApp.value = false;
}

onMounted(() => {

  window.addEventListener("message", (e) => {
    if (e && e.data && e.data == "SW_UPDATED") {
      window.caches.keys()
        .then(cache => {
          if (cache) {
            cache.forEach(name => {
              window.caches.delete(name);
            })
          }
        }).finally(() => {
          swal({
            icon: 'info',
            text: 'Por favor refresque la aplicación',
            title: "Nueva versión",
            confirmButtonText: "Actualizar"
          })
            .then((result) => {
              console.log("Se refresco la vista ");
              window.location.reload();
            })
        })
    }
  });
  //   window.addEventListener("online", () => {
  //     console.log("online")

  //   })
  //   window.addEventListener("offline", () => {
  //     console.log("offline")
  //   })
});
// onBeforeUnmount(() => {
//   window.removeEventListener("online", () => {
//     console.log("Event delete")

//   })
//   window.removeEventListener("offline", () => {
//     console.log("offline")
//   })
// })
service.getDb()
  .then(async (db) => {
    const users = await service.getUsers(db);
    if (users && users.length > 0) {
      if ($router.currentRoute._value.path.indexOf('login') >= 0) {
        $router.push("/event")
      }
    } else {
      $router.push("/login")
    }
  });
</script>

<script >
import { VOffline } from 'v-offline';
import SvgIcon from "vue3-icon";
export default {
  name: 'App',
  components: {
    VOffline,
    SvgIcon
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  margin: 10px;
  padding: 0;
  overflow-x: hidden;
}

.messageInstallApp {
  position: fixed;
  background: #ededed99;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 1000;
}

.card-view-APP {
  position: absolute;
  bottom: 0px;
  left: 20px;
  right: 20px;
  background: #fff;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
</style>
